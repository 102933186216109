<template>
	<div class="row match-height">
		<div class="col-lg-3 col-sm-6 col-12">
			<div
				:class="[
					'card',
					'be-loading',
					'gradBlu',
					'text-whit',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-content p-2">
					<i
						class="
              feather
              icon-shopping-cart
              font-large-3
              pull-left
              text-primary
              mt-1
            "
					></i>
					<div class="ml-3 pull-left">
						<h1 class="text-bold-700 text-whit font-large-2">
							{{ dashData.total }}
						</h1>
						<p class="mt-0 mb-0">Order this week</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-sm-6 col-12">
			<div
				:class="[
					'card',
					'be-loading',
					'gradOrang',
					'text-whit',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-content p-2">
					<i
						class="
              feather
              icon-briefcase
              font-large-3
              pull-left
              text-warning
              mt-1
            "
					></i>
					<div class="ml-3 pull-left">
						<h1 class="text-bold-700 text-whit font-large-2">
							{{ dashData.pending }}
						</h1>
						<p class="mt-0 mb-0">Total Pending</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-sm-6 col-12">
			<div
				:class="[
					'card',
					'be-loading',
					'gradGree',
					'text-whit',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-content p-2">
					<i
						class="
              feather
              icon-check-circle
              font-large-3
              pull-left
              text-success
              mt-1
            "
					></i>
					<div class="ml-3 pull-left">
						<h1 class="text-bold-700 text-whit font-large-2">
							{{ dashData.fulfilled }}
						</h1>
						<p class="mt-0 mb-0">Total Fulfilled</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-sm-6 col-12">
			<div
				:class="[
					'card',
					'be-loading',
					'gradRe',
					'text-whit',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-content p-2">
					<i
						class="
              feather
              icon-minus-circle
              font-large-3
              pull-left
              text-danger
              mt-1
            "
					></i>
					<div class="ml-3 pull-left">
						<h1 class="text-bold-700 text-whit font-large-2">
							{{ dashData.canceled }}
						</h1>
						<p class="mt-0 mb-0">Total Cancelled</p>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-6" v-if="user.role == 'Customer'">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Growtrade</h4>
				</div>

				<div v-if="user.reservedAccountNumber" class="card-body">
					<div>
						<div v-if="showAccountBalance">
							<h2 class="d-inline" style="font-weight: 700; font-size: 25px">
								&#8358;
								{{
									accountDetails.available_balance
										? accountDetails.available_balance.toFixed(2)
										: "0.00"
								}}
							</h2>
							<button
								class="d-inline btn btn-text"
								type="button"
								@click="toggleAccountBalance"
								style="padding-bottom: 20px"
							>
								<i
									style="font-weight: 700; font-size: 21px"
									class="feather icon-eye-off"
								></i>
							</button>
						</div>
						<div v-else>
							<h2
								class="d-inline"
								style="font-weight: 700; font-size: 25px; margin-top:10px"
							>
								****
							</h2>
							<button
								class="d-inline btn btn-text"
								type="button"
								@click="toggleAccountBalance"
								style="padding-bottom: 20px"
							>
								<i
									style="font-weight: 700; font-size: 21px"
									class="feather icon-eye"
								></i>
							</button>
						</div>
					</div>

					<div class="row mt-2" style="font-size: 16px">
						<span class="col-4">Bank Name: </span>
						<span class="text-primary col-8">{{
							accountDetails.bank_name ? accountDetails.bank_name : "N/A"
						}}</span>
					</div>
					<div class="row mt-2" style="font-size: 16px">
						<span class="col-4">Account Name: </span>
						<span class="text-primary col-8">{{
							accountDetails.account_name ? accountDetails.account_name : "N/A"
						}}</span>
					</div>
					<div class="row mt-2" style="font-size: 16px">
						<span class="col-4">Account Number:</span>
						<button
							class="d-inline btn btn-text text-primary col-1"
							type="button"
							@click="copyToC"
						>
							<i class="feather icon-copy"></i>
						</button>
						<span class="text-primary col-5" id="accountNo">{{
							accountDetails.account_number ? accountDetails.account_number : "N/A"
						}}</span>
					</div>
					<div class="text-right mt-3">
						<button
							type="button"
							class="btn btn-outline-primary"
							@click="addMoneyToAccount"
						>
							Add Money
						</button>
					</div>
				</div>
				<div v-else class="card-body">
					<p class="text-danger text-bold">You do not have an active account</p>
					<div class="text-right ml-2 mt-2">
						<button type="button" class="btn btn-primary" @click="showBankModal">
							Create Account
						</button>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>
		<div class="col-lg-6 mb-2" v-if="user.type == 'Distributor' && user.role != 'Customer' && user.role != 'Sales'">
			<!-- <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header"><h4 class="text-primary">Channels</h4></div>
				<div class="card card-table" v-if="dashData.hasOrders">
					<div class="row">
						<div class="col-md-6 flex-center">
							<apexchart
								type="radialBar"
								height="350"
								:options="chartOptionsChannels"
								:series="seriesChannels"
							></apexchart>
						</div>
						<div class="col-md-6 flex-center pr-3">
							<table class="table table-striped table-borderless">
								<tbody>
									<tr v-for="o in dashData.channels" :key="o.label">
										<td>
											<span class="float-right">{{ o.value }}</span>
											<i class="icon mdi mdi-album"></i> &nbsp;&nbsp;{{
												o.label
											}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						No orders yet today
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div> -->
			<div style="height:100%;">
				<div class="row h-100" v-if="clientName == 'uac'">
					<div class="col-md-12 px-1">
						<div class="row h-100 px-1">
							<div class="col-12 px-2 py-3 pf-container mb-3">
								<h6 class="text-primary">Top 3 Categories...</h6>
								<div class="w-100 mt-2">
									<div v-for="o in dashData.categories" :key="o.label" class="w-100 d-flex flex-row justify-content-between">
										<span>{{o.label}}</span>
										<span class="w-50 pl-1 text-left">{{o.value}}</span>
									</div>
								</div>
							</div>
							<div class="col-12 px-2 py-3 pf-container">
								<h6 class="text-primary">How Your Orders Came in...</h6>
								<div class="w-100 mt-2">
									<div v-for="o in dashData.channels" :key="o.label" class="w-100 d-flex flex-row justify-content-between">
										<span>{{o.label}}</span>
										<span class="w-50 pl-1 text-left">{{o.value}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row h-100" v-else>
					<div class="col-md-6 h-100 px-1">
						<div class="w-100 h-100 card">
							<div  :class="['d-flex flex-center text-center px-2 py-2','be-loading', { 'be-loading-active': isBusy }]">
								<h3 class="font-weight-bolder text-primary">PAY4ME</h3>
								<p class="mb-4">Process credit for your customers, get paid instantly</p>

								<button class="btn btn-rounded btn-primary" @click="openPay4meMod">
									Check Customer
								</button>
							</div>
						</div>
					</div>
					<div class="col-md-6 px-1">
						<div class="row h-100 px-1">
							<div class="col-12 px-2 py-3 pf-container mb-3">
								<h6 class="text-primary">Top 3 Categories...</h6>
								<div class="w-100 mt-2">
									<div v-for="o in dashData.categories" :key="o.label" class="w-100 d-flex flex-row justify-content-between">
										<span>{{o.label}}</span>
										<span class="w-50 pl-1 text-left">{{o.value}}</span>
									</div>
								</div>
							</div>
							<div class="col-12 px-2 py-3 pf-container">
								<h6 class="text-primary">How Your Orders Came in...</h6>
								<div class="w-100 mt-2">
									<div v-for="o in dashData.channels" :key="o.label" class="w-100 d-flex flex-row justify-content-between">
										<span>{{o.label}}</span>
										<span class="w-50 pl-1 text-left">{{o.value}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-6" v-if="!user.isSalesUser && user.type == 'Manufacturer'">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Top Groups</h4>
					<button
						v-if="dashData.hasOrders"
						class="btn btn-primary btn-sm"
						type="button"
						id="dropdownMenu2"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						@click="
							topGroupChartOptions.toggleChart = !topGroupChartOptions.toggleChart
						"
					>
						<span v-if="topGroupChartOptions.toggleChart"
							>Top 5 Groups by Order Amount</span
						>
						<span v-else>Top 5 Groups by Order Count</span>
					</button>
				</div>
				<div class="card card-table" v-if="dashData.hasOrders">
					<div class="row">
						<div class="col-md-12 flex-center p-3">
							<div v-if="topGroupChartOptions.toggleChart">
								<p class="font-weight-bold text-center text-success">
									Top 5 groups by Order count
								</p>
								<apexchart
									type="bar"
									height="300"
									:options="topGroupChartOptions.topFivegroupsbyOrderCount"
									:series="seriesTop5groupsbyOrderCount"
								></apexchart>
							</div>
							<div v-else>
								<p class="font-weight-bold text-center text-primary">
									Top 5 groups by Order Amount
								</p>
								<apexchart
									type="bar"
									height="300"
									:options="topGroupChartOptions.topFivegroupsbyAmount"
									:series="seriesTop5groupsbyAmount"
								></apexchart>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						data not found
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="col-lg-6 mb-2">
			<div :class="['card h-100', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Top Products</h4>
				</div>
				<div class="card card-table p-3" v-if="dashData.hasOrders">
					<apexchart
						type="bar"
						height="300"
						:options="topProductChartOptions"
						:series="seriesTopProduct"
					></apexchart>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						No orders yet today
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<!-- <div class="col-lg-6">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Top Categories</h4>
				</div>
				<div class="card card-table" v-if="dashData.hasOrders">
					<table class="table table-striped table-borderless">
						<tbody>
							<tr v-for="o in dashData.categories" :key="o.label">
								<td>
									<span class="float-right">{{ o.value }}</span>
									<i class="icon mdi mdi-tag text-primary mr-3"></i>
									&nbsp;&nbsp;{{ o.label }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						No orders yet today
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div> -->

		<div class="col-lg-6" v-if="user.isSalesUser && user.role == 'Customer'">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Distributor Network</h4>
				</div>
				<div class="card card-table" v-if="distributorNetwork.length">
					<table class="table table-striped table-borderless">
						<tbody>
							<tr v-for="p in distributorNetworkFiltered" :key="p.id">
								<td>
									<span class="float-right"
										><i class="icon mdi mdi-calendar-account text-success"></i>
										&nbsp;&nbsp; {{ formatDate(p.date) }}</span
									>
									{{ p.parentName }}
								</td>
							</tr>
						</tbody>
						<a
							v-if="distributorNetwork.length > 5"
							href="#"
							class="footer"
							@click.prevent="showAll(1)"
							>View all</a
						>
					</table>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-file-text"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						You are currently not attached to a distributor
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="col-lg-6" v-if="user.type == 'Manufacturer'">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-header">
					<h4 class="text-primary">Distributor Network</h4>
				</div>
				<div class="card card-table" v-if="distributors.length">
					<table class="table table-striped table-borderless">
						<tbody>
							<tr v-for="p in distributorsFiltered" :key="p.id">
								<td>
									<span class="float-right"
										><i class="icon mdi mdi-calendar-account text-success"></i>
										&nbsp;&nbsp; {{ formatDate(p.date) }}</span
									>
									{{ p.parentName }}
								</td>
							</tr>
						</tbody>
					</table>
					<a
						v-if="distributors.length > 5"
						href="#"
						class="footer"
						@click.prevent="showAll(2)"
						>View all</a
					>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-file-text"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						You are currently not attached to a distributor
					</h4>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="col-lg-6" v-if="user.type == 'Distributor' && user.role != 'Customer'">
			<div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
				<div class="card-heade p-2">
					<a
						v-if="manufacturers.length > 5"
						href="#"
						class="btn btn-sm btn-primary float-right"
						@click.prevent="showAll(3)"
						><i class="feather icon-eye"></i> &nbsp;VIEW ALL</a
					>
					<h4 class="text-primary">Manufacturer Network</h4>
				</div>
				<div class="card card-table" v-if="manufacturers.length">
					<table class="table table-striped table-borderless">
						<tbody>
							<tr v-for="p in manufacturersFiltered" :key="p.id">
								<td>
									<span class="float-right"
										><i class="icon mdi mdi-calendar text-success"></i>
										&nbsp;&nbsp; {{ formatDate(p.date) }}</span
									>
									<span
										><i class="feather icon-circle text-success"></i> &nbsp;
										{{ p.parentName }}</span
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-body" v-else>
					<div class="text-center" style="padding-top: 50px">
						<i style="font-size: 4.615rem" class="feather icon-file-text"></i>
					</div>
					<h4 class="text-center mt-2" style="padding-bottom: 50px">
						You are currently not attached to a manufacturer
					</h4>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-viewList"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1">
							<strong>{{ viewListType }}</strong>
						</h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<table
						id="ManufacturersExtTable"
						class="table table-striped table-borderless dataTable"
					>
						<tbody>
							<tr v-for="p in viewList" :key="p.id">
								<td>
									<span class="float-right"
										><i class="feather icon-calendar text-success"></i> &nbsp;{{
											formatDate(p.date)
										}}</span
									>
									<i class="feather icon-circle text-success"></i> &nbsp;
									{{ p.parentName }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

     <div class="modal fade colored-header colored-header-primary" backdrop="static" id="mod-subscription" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="text-center">
              <h2>Hi {{user.displayName}}, </h2>
              <p>Your Standard subscription plan has expired</p>

              <button type="button" class="btn btn-primary w-100 mt-2" @click="goToSubscribe"><span class="font-weight-bold">Subscribe</span></button>
            </div>
          
          </div>
        </div>
      </div>
      
		<CreateBankAccount @closeModal="closeBankModal" />
      <div class="modal fade colored-header colored-header-primary" backdrop="static" id="mod-subscription" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="text-center">
              <h2>Hi {{user.displayName}}, </h2>
              <p>Your Standard subscription plan has expired</p>

              <button type="button" class="btn btn-primary w-100 mt-2" @click="goToSubscribe"><span class="font-weight-bold">Subscribe</span></button>
            </div>
          
          </div>
        </div>
      </div>

	  <!-- pay4me Modal -->
	  	<div
			class="modal fade colored-header colored-header-primary"
			id="mod-pay4me"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1">
							<strong>Enter Customer/Merchant Phone Number</strong>
						</h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="closePay4meMod"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body p-3">
						<div class="form-group row">
							<label class="col-12 font-weight-bold col-sm-4 col-form-label "
								>Phone Number</label
							>
							<div class="col-12 col-sm-6">
								<input
									:class="[
										'form-control',
										{ 'is-invalid': payPhoneNumberInvalid },
									]"
									step=".01"
									min="0"
									max="10"
									type="number"
									placeholder="11 digits"
									v-model.trim="payPhoneNumber"
									:disabled="pay4meStep > 1"
								/>
								<ul
									v-if="payPhoneNumberInvalid"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">
										Number must be 11 digits.
									</li>
								</ul>
							</div>
							<div class="col-12 col-sm-2">
								<button v-if="pay4meStep === 2" class="btn btn-text" type="button" @click="changePay4meStep(1)">
									<span class="text-primary" style="text-decoration:underline">Change</span>
								</button>
							</div>
						</div>
						<div v-if="pay4meStep === 2" class="mt-2">
							<!-- <p>
								Great news! Your customer <strong>Rachael Flynn</strong> with phone number <strong>{{payPhoneNumber}}</strong> can get up to <strong>NGN450,000.00</strong> from Growtrade Pay4Me to buy goods from you.
								 Click the Continue button below to generate an order for your customer.
							</p> -->
							<p>
								{{nextStepMsg}}
							</p>
						</div>
						<!-- <div v-if="pay4meStep === 3" class="mt-2">
							<p>
								We will need some more information from your customer before activating them on Pay4me. Our team will engage them in the next 2 days so that you can sell to them without risk and enjoy repeat business.
							</p>
						</div> -->
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="closePay4meMod"
						>
							Cancel
						</button>
						<button
							v-if="pay4meStep === 1"
							style="width: 100px"
							:disabled="isBusyPay4Me"
							class="btn btn-primary"
							type="button"
							@click="checkCustomerPay4Me"
						>
							<span v-if="isBusyPay4Me">Busy...</span>
							<span v-else>Check</span>
						</button>
						<button
							v-if="pay4meStep === 2 && pay4Status === true"
							style="width: 100px"
							:disabled="isBusyPay4Me"
							class="btn btn-primary"
							type="button"
							@click="gotoOrdersPage"
						>
							<span v-if="isBusyPay4Me">Busy...</span>
							<span v-else>Confirm</span>
						</button>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import VueApexCharts from "vue-apexcharts";
	import CreateBankAccount from "@/components/BankAccount/CreateBankAccount.vue";

	export default {
		name: "dashboard",
		created() {
			this.load();
			this.getAccountDetails();
		},

  mounted() {
    
    if(this.user.isSubscriptionActive === false && this.user.type === "Distributor" && this.user.role !== "Customer"){
      this.openSubModal();
    }
    
    // if (!this.user.reservedAccountNumber) {
    //   $("#mod-add-onepipe-account").modal({ backdrop: "static", keyboard: false });
    // }
  },

		components: {
			apexchart: VueApexCharts,
			CreateBankAccount,
		},
		data() {
			return {
				isBusy: true,
				dashData: {},
				distributorNetwork: [],
				manufacturers: [],
				distributors: [],
				viewList: [],
				viewListType: "",
				series: [],
				chartOptions: {
					chart: {
						height: 350,
						type: "radialBar",
					},
					colors: ["#FF9F43", "#7367F0", "#EA5455"],
					plotOptions: {
						radialBar: {
							hollow: {
								margin: 15,
								size: "45%",
							},
							dataLabels: {
								name: {
									fontSize: "18px",
									offsetY: -10,
									show: true,
								},
								value: {
									color: "#111",
									fontSize: "30px",
									show: true,
								},
								total: {
									show: false,
									label: "Total",
								},
							},
						},
					},
					stroke: {
						lineCap: "round",
					},
					labels: ["Pending", "Fulfilled", "Canceled"],
				},
				seriesChannels: [],
				chartOptionsChannels: {
					chart: {
						height: 350,
						type: "radialBar",
					},
					colors: ["#FF9F43", "#7367F0", "#EA5455"],
					plotOptions: {
						radialBar: {
							hollow: {
								margin: 15,
								size: "45%",
							},
							dataLabels: {
								name: {
									fontSize: "18px",
									offsetY: -10,
									show: true,
								},
								value: {
									color: "#111",
									fontSize: "30px",
									show: true,
								},
								total: {
									show: false,
									label: "Total",
								},
							},
						},
					},
					stroke: {
						lineCap: "round",
					},
					labels: [],
				},
				topProductChartOptions: {
					chart: {
						id: "Top Products Chart",
					},
					xaxis: {
						categories: []
					},
					yaxis: {
						labels: {
							formatter: function (val) {
								return helper.formatNumber(val);
							}
						}
					},
					 dataLabels: {
						enabled: true,
						formatter: function (val, opt) {
							return helper.formatNumber(val)
						},
					},
				},
				topGroupChartOptions: {
					toggleChart: false,
					topFivegroupsbyOrderCount: {
						chart: {
							id: "Top 5 groups by order count",
						},
						colors: ["#94B832"],
						xaxis: {
							categories: [],
						},
						yaxis: {
						labels: {
							formatter: function (val) {
								return helper.formatNumber(val);
							}
						}
					},
					 dataLabels: {
						enabled: true,
						formatter: function (val, opt) {
							return helper.formatNumber(val)
						},
					},
					},
					topFivegroupsbyAmount: {
						chart: {
							id: "Top 5 groups by amount",
						},
						colors: ["#4839EB"],
						xaxis: {
							categories: [],
						},
						yaxis: {
						labels: {
							formatter: function (val) {
								return helper.formatMoney(val);
							}
						}
					},
					 dataLabels: {
						enabled: true,
						formatter: function (val, opt) {
							return helper.formatMoney(val)
						},
					},
					},
				},
				seriesTopProduct: [
					{
						name: "Sale Count",
						data: [],
					},
				],
				seriesTop5groupsbyOrderCount: [
					{
						name: "Order Count",
						data: [],
					},
				],
				seriesTop5groupsbyAmount: [
					{
						name: "Amount(₦)",
						data: [],
					},
				],
				seriesTopProductLabels: [],
				showAccountBalance: false,
				accountDetails: {},
				grouData: null,
				pay4meStep: 1,
				nextStepMsg:"",
				pay4Status: null,
				pay4Limit: 0,
				payPhoneNumber:"",
				payUserId:"",
				isBusyPay4Me: false,
				payPhoneNumberInvalid: false,
				clientName: process.env.VUE_APP_CLIENT_NAME
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},

			distributorNetworkFiltered: function() {
				if (this.distributorNetwork.length > 5) {
					let clone = this.distributorNetwork.slice();
					return clone.splice(0, 5);
				} else {
					return this.distributorNetwork;
				}
			},

			manufacturersFiltered: function() {
				if (this.manufacturers.length > 5) {
					let clone = this.manufacturers.slice();
					return clone.splice(0, 5);
				} else {
					return this.manufacturers;
				}
			},

			distributorsFiltered: function() {
				if (this.distributors.length > 5) {
					let clone = this.distributors.slice();
					return clone.splice(0, 5);
				} else {
					return this.distributors;
				}
			},
		},

		methods: {
			openPay4meMod(){
				$("#mod-pay4me").modal("show");
			},
			closePay4meMod(){
				$("#mod-pay4me").modal("hide");
				this.pay4meStep = 1;
				this.nextStepMsg="";
				this.pay4Status= null;
				this.pay4Limit= 0;
				this.payPhoneNumber="";
				this.payUserId="";
				this.payPhoneNumberInvalid= false;
			},
			changePay4meStep(step){
				this.pay4meStep = step;
			},
			gotoOrdersPage(){
				$("#mod-pay4me").modal("hide");
				//initialize order with buyer id
				this.$router.push(`/orders/create/${this.payUserId}?credit=${this.pay4Limit}`);
			},
			async checkCustomerPay4Me(){
				if(!this.validatePayNumber()){
					return;
				}
				let phNo= this.payPhoneNumber.slice(1);
				this.isBusyPay4Me = true;
				await this.$http
					.get(`/Credits/check/234${phNo}`)
					.then(function(res) {
						this.pay4Limit = res.body.creditLimit;
						this.nextStepMsg = res.body.message;
						this.pay4Status = res.body.status;
						this.payUserId= res.body.userId;
						this.isBusyPay4Me = false;
						this.pay4meStep = 2;
					})
					.catch(function() {
						this.isBusyPay4Me = false;
					});
			},

			validatePayNumber(){
				if(!this.payPhoneNumber || this.payPhoneNumber.length !== 11){
					this.payPhoneNumberInvalid =true;
					return false
				}

				this.payPhoneNumberInvalid =false;
				return true;
			},

			async load() {
				await this.$http
					.get("/Reports/getDashboardReport")
					.then(function(res) {
						this.dashData = res.body;
						this.series.push(res.body.pending);
						this.series.push(res.body.fulfilled);
						this.series.push(res.body.canceled);
						if (
							this.dashData.enterpriseProductAmountByGroup &&
							this.dashData.enterpriseProductCountByGroup
						) {
							this.getTopGroupStats();
						}
						for (var i = 0; i < res.body.products.length; i++) {
							var obj = res.body.products[i];
							this.seriesTopProduct[0].data.push(obj.value);
							this.topProductChartOptions.xaxis.categories.push(obj.label);
						}
						for (var j = 0; j < res.body.channels.length; j++) {
							var obj = res.body.channels[j];
							this.seriesChannels.push(obj.value);
							this.chartOptionsChannels.labels.push(obj.label);
						}
					})
					.catch(function() {});

				if (this.user.role == "Customer") {
					this.getDistributorNetwork();
				} else if (this.user.type == "Distributor") {
					this.getManufacturers();
				} else if (this.user.type == "Manufacturer") {
					this.getDistributors();
				}

				this.isBusy = false;
			},

			getTopGroupStats() {
				for (var y = 0; y < this.dashData.enterpriseProductAmountByGroup.length; y++) {
					var obj = this.dashData.enterpriseProductAmountByGroup[y];
					this.seriesTop5groupsbyAmount[0].data.push(obj.value);
					this.topGroupChartOptions.topFivegroupsbyAmount.xaxis.categories.push(
						obj.label,
					);
				}
				for (var x = 0; x < this.dashData.enterpriseProductCountByGroup.length; x++) {
					var obj = this.dashData.enterpriseProductCountByGroup[x];
					this.seriesTop5groupsbyOrderCount[0].data.push(obj.value);
					this.topGroupChartOptions.topFivegroupsbyOrderCount.xaxis.categories.push(
						obj.label,
					);
				}
			},

			async getDistributorNetwork() {
				this.isBusy = true;

				await this.$http
					.get("/Users/getParents/" + this.user.id)
					.then(function(res) {
						this.distributorNetwork = res.body;
					})
					.catch(function(err) {});

				this.isBusy = false;
			},

			async getManufacturers() {
				this.isBusy = true;

				await this.$http
					.get("/Accounts/getParents/" + this.user.parentId)
					.then(function(res) {
						this.manufacturers = res.body;
					})
					.catch(function(err) {});

				this.isBusy = false;
			},

			async getDistributors() {
				this.isBusy = true;

				await this.$http
					.get("/Accounts/getChildren/" + this.user.parentId)
					.then(function(res) {
						this.distributors = res.body;
					})
					.catch(function(err) {});

				this.isBusy = false;
			},

			showAll(listType) {
				switch (listType) {
					case 1:
						this.viewList = this.distributorNetwork;
						this.viewListType = "Distributor Network";
						break;
					case 2:
						this.viewList = this.distributors;
						this.viewListType = "Distributor Network";
						break;
					default:
						this.viewList = this.manufacturers;
						this.viewListType = "Manufacturer Network";
						break;
				}

				$("#mod-viewList").modal("show");
			},

			formatDate(date) {
				return moment(date).format("ll");
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			testAlert() {
				alert("This is a test");
			},

			async getAccountDetails() {
				await this.$http
					.post("/Payments/getAccountBalance")
					.then(function(res) {
						this.accountDetails = res.data.data;
					})
					.catch(function() {});
			},

			toggleAccountBalance() {
				this.showAccountBalance = !this.showAccountBalance;
			},

			copyToC() {
				var copyText = document.getElementById("accountNo").innerHTML;

				document.addEventListener(
					"copy",
					function(e) {
						e.clipboardData.setData("text/plain", copyText);
						e.preventDefault();
					},
					true,
				);

				document.execCommand("copy");
			},
			addMoneyToAccount() {
				this.$router.push("/merchant/growtrade");
			},
			closeBankModal() {
				$("#mod-add-onepipe-account").modal("hide");
			},
			showBankModal() {
				$("#mod-add-onepipe-account").modal("show");
			},
      openSubModal(){
        $("#mod-subscription").modal("show");
        $("#mod-subscription").on('hide.bs.modal', function(){
          return false;
        });
      },

      closeSubModal(){
        $("#mod-subscription").on('hide.bs.modal', function(){
          return true;
        });
        $("#mod-subscription").modal("hide");
        $(document.body).removeClass("modal-open");
        $(".modal-backdrop").remove();
        
      },

      goToSubscribe(){
        this.closeSubModal();
        this.$router.push('/billing-subscription');
      }

    
		},
	};
</script>

<style scoped>
	.footer {
		background-color: #fafafa;
		font-size: 1rem;
		color: #8a8a8a;
		text-align: center;
		padding: 15px 0;
		border-top: 1px solid #ebebeb;
		display: block;
		outline: 0;
	}

	.text-bold-600 {
		font-weight: 600;
	}

	.bg-light-grey {
		background-color: #f5f5f5;
	}

	.card {
		margin-bottom: 2.2rem;
		border: none;
		border-radius: 0.5rem;
		box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.01);
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.pf-container{
		background-color: #fff;
		border-radius: 0.5rem;
		box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.01);
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.gradGreen {
		/*background-image: linear-gradient(to right, #67B26F, #4ca2cd);*/
		background: #1abc9c;
	}

	.gradBlue {
		/*background-image: linear-gradient(to right, #00d2ff, #928dab);*/
		background: #3498db;
	}

	.gradOrange {
		/*background-image: linear-gradient(to right, #ffb75e, #ed8f03);*/
		background: #f39c12;
	}

	.gradRed {
		/*background-image: linear-gradient(to right, #f85032, #e73827);*/
		background: #e74c3c;
	}

	.icon-2x {
		font-size: 4rem;
	}

	.flex-center {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		align-self: center;
	}

	/* pay4me */

</style>
